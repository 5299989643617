import { urlJoin } from '@digital-spiders/misc-utils';
import { extractRootDomain } from '@digital-spiders/tracking-data';

export const AUTHOR_NAME = 'Hook Point';
export const COPYRIGHT_DEFAULT_YEAR = 2023;

export const DEFAULT_LANG = 'en';
export const ALL_LANGS = ['en'] as const;

export const DEFAULT_SITE_DOMAIN = 'goviral.hookpoint.com';
export const DEFAULT_SITE_BASE_URL = 'https://goviral.hookpoint.com';

export const SITE_DOMAIN =
  process.env.NODE_ENV === 'development'
    ? 'localhost:8000'
    : process.env.GATSBY_SITE_DOMAIN || DEFAULT_SITE_DOMAIN;

export const SITE_BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8000'
    : process.env.GATSBY_SITE_BASE_URL || DEFAULT_SITE_BASE_URL;

export const ROOT_DOMAIN =
  process.env.NODE_ENV === 'development' ? 'localhost:8000' : extractRootDomain(SITE_DOMAIN);

export const FUNCTIONS_BASE_URL =
  process.env.NODE_ENV === 'development' ? '__development__' : '__production__';

export const MAX_OG_DESCRIPTION_LENGTH = 300;

export const WISTIA_BASE_URL = 'https://fast.wistia.com/embed/iframe/';
export const WISTIA_VIDEO_QUERY_PARAM = 'wvid';

export const SIGN_IN_URL = 'https://viral.hookpoint.com/my-account';
export const CHECKOUT_URL = 'https://viral.hookpoint.com/checkout';

export const GOOGLE_OPTIMIZE_ID = process.env.GATSBY_GOOGLE_OPTIMIZE_ID;
export const SHOULD_RUN_GOOGLE_OPTIMIZE =
  process.env.NODE_ENV === `production` && !!GOOGLE_OPTIMIZE_ID;

export const ALLOWED_BANNERS = ['guide-thank-you', 'guide-downloading'] as const;
export const BANNER_QUERY_PARAM = 'banner';
export const FILE_QUERY_PARAM = 'file';
export const NOAUTODOWNLOAD_QUERY_PARAM = 'noautodownload';

export const FILE_DOWNLOAD_URLS_BY_PARAM_VALUE = {
  'guide-to-going-viral':
    'https://hookpoint.s3.us-west-1.amazonaws.com/HookPointGuideToGoingViral.pdf',
  'oriflame-guide': 'https://hookpoint.s3.us-west-1.amazonaws.com/GuidetoGoingViral.pdf',
};
export const DEFAULT_SCHEDULE_ONCE_CALENDAR = 'HookPointDiscovery17';

export const CALL_BOOKED_URL = '/call-booked';

export const PAGE_DOCUMENT_TYPES = ['guide-workshop', 'lp-vsl-page'] as const;

export const PAGE_TYPE_TO_PATH_FUNCTION = {
  'guide-workshop': pageDoc => urlJoin(pageDoc.slug.current),
  'lp-vsl-page': pageDoc => urlJoin(pageDoc.slug.current),
};

export const VIDEO_TYPES_MAP = {
  tiktok: {
    type: 'tiktok',
    title: 'TikTok',
    formatRegex:
      /^.*https:\/\/(?:m|www|vm)?\.?tiktok\.com\/(?:(?:.*\b(?:(?:usr|v|embed|user|video)\/|\?shareId=|\&item_id=)(\d+))|[^/?#&]+).*$/,
  },
  instagram: {
    type: 'instagram',
    title: 'Instagram',
    formatRegex: /^(?:https?:\/\/)?(?:www\.)?instagram\.com\/(?:p|reel)\/([^/?#&]+).*$/,
  },
  youtube: {
    type: 'youtube',
    title: 'Youtube',
    formatRegex:
      /^(?:https?:\/\/)?(?:(?:(?:www\.)?youtube\.com\/(?:watch\?v=|embed\/|shorts\/))|(?:youtu\.be\/))([^/?#&]+).*$/,
  },
  wistia: {
    type: 'wistia',
    title: 'Wistia',
    formatRegex: /^https:\/\/(.*\.)?wistia\.com|wistia\.net\/(medias|embed)\/.*/,
  },
  vimeo: {
    type: 'vimeo',
    title: 'Vimeo',
    formatRegex: /^https:\/\/(player\.)?vimeo\.com\/video\/\d+/,
  },
} as const;
